@font-face {
  font-family: roboHeader;
  src: url(../fonts/Roboto-Black.ttf);
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: #000;
  color: #fff;
  border-bottom: solid 2px #444;
}

.header > h1 {
  font-size: 2rem;
  font-family: roboHeader;
  padding: 20px;
  margin: 0;
}

.hamburger {
  height: 30px;
  padding: 4px;
}

.wideNav {
  display: none;
}

.dropMenu {
  background-color: #000;
  color: #fff;
  text-align: center;
  position: fixed;
  height: 100vh;
  width: 100%;
}

.dropMenu ul {
  list-style: none;
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}
.dropMenu li {
  border-bottom: 2px solid #444;
  padding: 30px 0;
}
@media (min-width: 800px) {
  .header {
    justify-content: space-around;
  }

  .pixel {
    display: none;
  }

  .header > h1 {
    justify-self: flex-start;
  }

  .wideNav {
    display: block;
  }

  .wideNav > ul {
    list-style: none;
    display: flex;
    flex-direction: row;
  }

  .wideNav > ul > li {
    padding: 10px;
  }

  .hamburger {
    display: none;
  }
}
