.footer {
  background-color: #000;
  color: #ddd;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.footer img {
  height: 20px;
  margin: 0 20px;
}

.pixel2 {
  background-color: #000;
  height: 1px;
  width: 1px;
}

.copyright {
  font-size: 0.7rem;
}
