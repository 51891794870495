a {
  color: #ddd;
  text-decoration: none;
}

.portfolioWrapper {
  margin-top: 50px;
  color: #ddd;
  padding-bottom: 50px;
}

.portfolioBox {
  display: flex;
  flex-direction: column;
  margin: 30px auto 0;

  width: 80%;
  background-color: #2229;
  border: solid 2px #444;
  border-radius: 5px;
  overflow: hidden;
}

.portfolioBox img {
  width: 100%;
}

.portfolioText {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-around;
}

.tagsFrame {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 10px;
}

.tagItem {
  display: block;
  color: #000;
  background-color: #ddd;
  margin: 0 2px;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 0.6rem;
}

.portfolioBox h3 {
  padding: 10px;
  margin: 0;
  font-size: 1.2rem;
  text-align: center;
  color: #ddd;
}

.portfolioBox p {
  padding: 5px;
  margin: 0;
  text-align: center;
  font-size: 0.8em;
}

.sourceCode {
  display: flex;
  justify-content: center;
}

.sourceCode span {
  text-decoration: underline;
  margin: 2px 5px;
}

@media (min-width: 1000px) {
  .portfolioBox {
    flex-direction: row;

    width: 60%;
  }

  .portfolioBox img {
    width: 300px;
    height: 100%;
  }
}
